
import commaNumber from 'comma-number'
import Dropdown from './Dropdown.vue'
import analytics from '@/helpers/analytics'

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      maxShowPartners: 8,
      maxShowItems: 10
    }
  },
  computed: {
    deeplinks() {
      return this.$store.state.deeplinks
    },
    minPriceFormatted() {
      return commaNumber(this.item.minPrice)
    },
    providerName() {
      return this.item.providers[0].name
    },
    shouldShowPartnerName() {
      return this.item.cpc > 0
    },
    getPickUpLocation() {
      return this.$store.state.searchCarLocationCodes
    },
    returnSameLocation() {
      return this.item.pickupLocation.address === this.item.dropoffLocation.address
    },
    parsedItems() {
      const parsedPartners = this.item.providers.slice(1, this.maxShowPartners).map(provider => ({
        ...provider, component: 'DropdownPartnerItem'
      }))

      const maxShowDiff = this.maxShowItems - parsedPartners.length

      const parsedCompare = this.$store.state.compareItems.slice(0, maxShowDiff).map((compareItem) => {
        return { ...compareItem, component: 'DropdownCompareItem' }
      })

      return [...parsedPartners, ...parsedCompare]
    },
  },
  methods: {
    handleCardClick() {
      this.shouldShowPartnerName ? this.openPartnerDeeplinkUrl() : this.openCompareModal()
    },
    clickHandler() {
      return this.openPartnerDeeplinkUrl()
      // return this.shouldShowPartnerName ? this.openPartnerDeeplinkUrl() : this.openCompareModal()
    },
    clickPhoneQuote() {
      analytics.track('meta-unit-phone-click', this)
      window.top.location = `tel:${this.item.phoneQuote.number}`
    },
    partnerClick() {
      const partnerCpc = Number(this.item.cpc).toFixed(2)
      const currency = 'USD'

      // google conversion
      if (this.$gtag && this.$store.state.siteConfig.googleConversion) {
        this.$gtag.event('conversion', {
          send_to: this.$store.state.siteConfig.googleConversion,
          value: partnerCpc,
          currency
        })
      }
      // Bing conversion
      if (window.uetq) {
        window.uetq.push('event', 'purchase', {
          revenue_value: partnerCpc,
          currency
        })
      }
      // Adara event
      this.fireAdaraEvent(this.item)
    },
    openPartnerDeeplinkUrl() {
      // Skip triggering the event for fallback partner (cpc = 0)
      if (this.item.cpc > 0) {
        this.partnerClick()
      }
      console.log('LC', this.item.providers[0]);
      analytics.track('pricelink-click', this)
      console.info('Open partner deeplink URL')
      window.newTab = window.open(this.item.providers[0].partnerDeeplinkUrl, '_blank')
    },
    openCompareModal() {
      if (this.$store.state.isCompareToAdsModalLoaded) {
        this.$store.commit('setInlineAdDeeplink', this.item.partnerDeeplinkUrl)
        this.$emit('openCompareModal')
        return
      }

      // Fallback if compare modal is not loaded
      this.openApiDeeplinkUrls()
    },
    openApiDeeplinkUrls() {
      analytics.track('view-deals-click', this)
      console.info('Open API deeplink URLs')
      const deeplinks = this.item.apiDeeplinkUrls
      window.newTab = window.open('', '_blank')

      if (deeplinks[0]) {
        window.newTab.location.href = deeplinks[0].redirect
      }

      if (deeplinks[1]) {
        window.location.href = deeplinks[1].redirect
      }

      if (deeplinks[2] && window.popUnder) {
        window.popUnder.location.href = deeplinks[2].redirect
        window.popUnder.moveTo(0, 0)
        window.popUnder.resizeTo(screen.width, screen.height)
      }
    },
    compareAll(data) {
      this.$emit('compareAll', data)
    },
    fireAdaraEvent (item) {
      if (Object.keys(item).length === 0) return

      const adaraParams = {
        travelProductType: 'Car',
        rentalCompany: item.agency.name,
        vehicleModel: item.name,
      }
      try {
        window.adara('send', {
          pageType: 'Purchase Item',
          siteLanguage: this.$store.state.language,
          siteCountry: this.$store.state.country,
          ...adaraParams
        })
      } catch (e) {
        console.info('Error firing Adara event')
      }
    }
  }
}
